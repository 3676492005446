#side-img {
  pointer-events: none;
  position: fixed;
  overflow: hidden;
  z-index: 3;
  transition: left 0.1s ease-in;
  top: calc(
    50% - 100px
  ); /* Center the image vertically by taking half its height */
}

@media screen and (min-width: 1200px) {
  #side-img {
    height: 250px;
    width: 250px;
    left: -250px;
    top: calc(50% - 125px); /* Adjust for half the height */
  }
}

@media screen and (max-width: 1199px) {
  #side-img {
    height: 200px;
    width: 200px;
    left: -75px;
    top: calc(50% - 100px); /* Adjust for half the height */
    transition: left 0.2s ease-in;
  }
}

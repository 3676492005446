.add-product-container {
    padding: 20px;
  }
  
  .add-product-form {
    display: flex;
    flex-direction: column;
  }
  
  .add-product-form input[type="text"] {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .add-product-form #description {
    border: 1px solid rgba(128, 128, 128, 0.5);
    padding: 10px;
    min-height: 150px;
    border-radius: 4px;
  }
  
  .add-product-form button {
    align-self: center;
    padding: 7px 20px;
    color: white;
    font-size: 20px;
  }
  
  .add-product-form button[type="submit"] {
    background-color: #2da0e4;
  }
  .add-product-form button[type="submit"]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .back-to-category {
    background-color: black;
    color: white;
    padding: 10px;
  }
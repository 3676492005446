#social-details-container {
  width: 100%;
  padding: 2em;
  max-width: 1500px;
  background: #f4f4f4;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0;
}

#social-title {
  font-size: 1.5em;
  margin-bottom: 1em;
  color: #282828;
  text-transform: uppercase;
}

.contact-info {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 1.5em;
}

.contact-detail {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  flex-direction: column;
}

.social-icon,
.contact-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .contact-info {
    flex-direction: column;
    align-items: center;
  }
}

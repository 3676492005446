:root {
  --form-border: 40px;
}

#hero-form-container {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}
#hero-form-card {
  height: auto;
  width: 100%;
  max-width: 400px;

  border-top-left-radius: var(--form-border);
  border-bottom-right-radius: var(--form-border);
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 5px white;
}
.form-slide {
  width: 100%;
  background-color: white;
  padding: 30px 40px;
  overflow: visible;
  flex: 1;
  height: fit-content;
}
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #282828;
  outline: none;
  cursor: pointer;
  position: relative; /* Added this line */
}

input[type="radio"]::before {
  content: "";
  width: 8px; /* Adjust this size if necessary */
  height: 8px; /* Adjust this size if necessary */
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none; /* Added this line */
}

input[type="radio"]:checked {
  background-color: #282828;
}

input[type="radio"]:checked::before {
  display: block; /* Added this line */
}

#hero-form-buttons-container {
  color: white;
  display: flex;
  flex-direction: row-reverse;
  align-items: start;
  justify-content: start;
  padding: 10px;
}
#hero-form-buttons-container button {
  padding-right: 10px;
  padding-top: 10px;
  margin: 10px;
}
.slide-title {
  transform: translateX(10px);
  font-size: 25px;
  line-height: 110%;
  overflow: visible;
}
#slides-container {
  display: flex;
  flex-direction: row;
  width: 500%;
  transition: 0.5s ease-in-out;
  overflow-y: hidden;
}
.navigate-slide {
  width: 50%;
  padding: 10px;
}
#next {
  background-color: #2da0e4;
  outline: none;
}
#previous {
  outline: none;
  background-color: #e02780;
}

#form-title {
  font-size: 30px;
  color: white;
}
@media screen and (max-width: 768px) {
  #hero-form-container {
    max-width: 95vw;
  }
  .form-slide {
    padding: 30px 30px;
  }
}
#slide-options-container {
  display: flex;
  flex-direction: row;
  margin: 5px;
}
#slide3 textarea {
  border: 1px solid gray;
  width: 100%;
  min-height: 150px;
  max-height: 200px;
  margin-top: 20px;
  outline: none;
  padding: 10px;
}
#options4 input[type="text"],
#options4 input[type="tel"],
#options4 input[type="email"] {
  width: 100%;
  border: 1px solid gray;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 7px;
}
#quick-submit-button {
  background-color: #2da0e4;
  padding: 5px 35px;
  color: white;
  font-size: 20px;
}
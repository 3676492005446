.admin-add-client-container {
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f4f4f4;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.add-client-title {
  font-size: 30px;
  font-weight: 700;
}

.add-client-form {
  display: flex;
  flex-direction: column;
}

.add-client-input-text,
.add-client-input-file,
.add-client-button {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-client-button {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.add-client-button:hover {
  background-color: #0056b3;
}

#mobile-header-container {
  height: 100px;
  background-color: white;
  box-shadow: 0px 5px 20px -10px #000000;
  overflow: visible;
  z-index: 1000;
  position: relative;
}

#mobile-header-container * {
  overflow: visible;
}
.menu-icon {
  position: absolute;
  width: 30px;
  margin-right: 30px;
  top: 50%;
  transform: translate(0%, -50%);
  cursor: pointer;
  z-index: 999;
}

.menu-icon:before,
.menu-icon:after,
.menu-icon div {
  background: #282828;
  content: "";
  display: block;
  height: 4px;
  border-radius: 3px;
  background-color: #282828;
  margin: 4px 0;
  transition: 0.3s;
}
.menu-icon.active {
  position: fixed;
  width: 30px;
  top: 50px;
}
.menu-icon.active:before {
  transform: translateY(8px) rotate(45deg);
  background-color: white;
}

.menu-icon.active:after {
  transform: translateY(-8px) rotate(-45deg);
  background-color: white;
}

.menu-icon.active div {
  transform: scale(0);
}

#mobile-header-content {
  height: 100vh;
  width: 100vw;
  background-color: #282828;
  transform: translateX(100%);
  transition: transform 0.35s ease-in-out;
  z-index: 998;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu-icon.active + #mobile-header-content {
  transform: translateX(0);
}
@media screen and (max-width: 1050px) {
  body.no-scroll,
  html.no-scroll {
    overflow: hidden;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.mobile-link {
  color: white;
  font-size: 2rem;
  padding: 10px;
  opacity: 0;
}
.mobile-contact {
  background-color: #00afee;
  color: white;
  padding: 7px 25px;
  border-radius: 10px;
  font-size: 2.5rem;
  margin-top: 30px;
}

.mobile-link-enter {
  animation: slideInFromRight 0.5s forwards;
}

.mobile-link-leave {
  animation: slideOutToRight 0.5s forwards;
}
#mobile-social-container {
  display: flex;
  flex-direction: row;
  bottom: 20px;
  position: absolute;
}
#mobile-social-container img {
  width: 60px; /* Set the icon size. Adjust as needed */
  height: auto;
  margin: 10px;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.img-enter {
  animation: fadeIn 0.5s forwards;
}

.img-leave {
  animation: fadeOut 0.5s forwards;
}
#mobile-search {
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 100%;
}
#mobile-search form {
  height: 100%;
  width: 100vw;
  padding: 15px;
}
#mobile-search form input[type="text"] {
  height: 100%;
  width: 0;
  border-bottom: 1px solid black;
  z-index: 100;
  outline: none;
  transition: 0.3s ease;
}
body.no-scroll {
  overflow: hidden;
}
#mobile-cart {
  position: absolute;
  left: 70px;
  top: calc(50% + 5px);
  transform: translateY(-50%);
  z-index: 998;
}
.mobile-cart-icon {
  font-size: 1.4rem;
}
.mobile-search-results {
  width: 100vw;
  background-color: white;
  height: auto;
  padding: 10px;
}
.mobile-search-result {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.mobile-search-result img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
}

.mobile-search-result:last-child {
  margin-bottom: 0;
}
.mobile-search-result a {
  margin-right: 10px;
}
.mobile-search-result .result-image {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.watch-results-style {
  background-color: #282828;
  color: white;
  padding: 3px 9px
}
.demo button{
  position: absolute;
  bottom: 50px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  transform: translate(-50%);
  color: #e30679;
  font-size: 45px;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
  direction: rtl;
  transition: .2s;
}
#scrollSection button{
  padding-top: 80px;
}
#scrollSection button span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #e30679;
  border-bottom: 1px solid #e30679;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb07 2s infinite;
  animation: sdb07 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}
#scrollSection button span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
#scrollSection button span:nth-of-type(2) {
  top: 16px;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
#scrollSection button span:nth-of-type(3) {
  top: 32px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
@keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.animate__animated.animate__bounce {
  animation-iteration-count: infinite;
}
#about-page-container {
    background-color: #f5f5f5;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .about-content-section {
    max-width: 800px;
    margin: 0 auto;
    background: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
  }
  
  .about-title {
    color: #333333;
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .about-description,
  .products-services-description {
    color: #555555;
    font-size: 17px;
    line-height: 1.5;
    margin: 10px 0;
  }
  
  .products-services-title {
    color: #333333;
    font-size: 20px;
    margin-top: 20px;
  }

#cart-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 100%;
}

#cart-page-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #282828;
}

.cart-page-product-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
}
.cart-page-product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 20px;
}

.cart-page-table {
  width: 100%;
  display: table;
  max-width: 1200px;
}

.cart-page-thead tr th {
  padding: 8px;
  text-align: right;
  border: 1px solid #dee2e6; /* Add borders */
  background-color: #f8f9fa; /* Add background color */
  width: 33.33%;
}

.cart-page-tbody tr td {
  padding: 8px;
  text-align: right;
  border: 1px solid #dee2e6; /* Add borders */
  background-color: #f8f9fa; /* Add background color */
  width: 33.33%;
}

.cart-page-quantity,
.cart-page-comment {
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  width: 100%;
}

.cart-page-comment {
  min-height: 60px;
  max-height: 120px;
}

.cart-page-remove-btn {
  color: white;
  margin: 10px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px;
  background-color: red;
  width: fit-content;
  overflow-wrap: normal;
}

.cart-page-contact-form {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  max-width: 500px;
}

.cart-page-contact-form input,
.cart-page-contact-form button {
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.cart-page-contact-form button {
  cursor: pointer;
  background-color: #282828;
  color: white;
  border: none;
}
.cart-page-contact-form button:disabled {
  cursor: pointer;
  background-color: #666666;
  color: white;
  border: none;
  cursor: no-drop;
}

/* Media query for mobile view */
@media (max-width: 767px) {
  #cart-page-container {
    padding: 5px;
  }
  .cart-page-product-wrapper {
    flex-direction: column;
    padding: 0;
  }
  .cart-page-thead tr th {
    display: none;
  }
  .cart-page-tbody tr {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid black;
  }
  .cart-page-tbody tr td {
    width: calc(100vw - 10px);
  }
  .cart-page-product-image {
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .cart-page-thead tr th:nth-child(2),
  .cart-page-tbody tr td:nth-child(2) {
    width: 15%; /* Smaller width for Quantity column */
  }

  .cart-page-thead tr th:nth-child(1),
  .cart-page-thead tr th:nth-child(3),
  .cart-page-tbody tr td:nth-child(1),
  .cart-page-tbody tr td:nth-child(3) {
    width: 35%; /* Adjusting other columns to fill the remaining space */
  }
  .cart-page-product-wrapper p {
    margin: 10px;
  }
}
.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid #ffffff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.thank-you-message {
  text-align: center;
  padding: 30px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  color: #282828;
  max-width: 500px;
  margin: 0 auto;
}

.thank-you-message h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.thank-you-message p {
  font-size: 1rem;
  margin-bottom: 15px;
}

.thank-you-message a {
  color: #2da0e4;
  font-size: 1.5rem;
  text-decoration: none;
}

.cardLink {
  text-decoration: none;
  display: flex;
  justify-content: center;
  overflow: visible;
  width: 100%;
  max-width: 250px;
  max-height: 250px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  overflow: visible;
  position: relative;
  border-radius: 25px;
  align-self: center;
  justify-self: center;
}

.cardContainer {
  display: grid;
  width: 80vw;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  overflow: visible;
  align-items: center;
  justify-content: center;
}

.card {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  color: #282828;
  box-shadow: 0px 20px 30px -15px #0000006c;
  text-align: center;
  border-radius: 25px;
  transition: 250ms ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 300px;
  padding-bottom: 100%;
  overflow: hidden;
  z-index: 2;
}
.card:hover {
  transform: translateY(-15px);
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
.card:hover .image {
  scale: 1.15;
}
.card:hover .title {
  transform: translate(-50%, calc(-90% - 15px));
}
.title {
  color: white;
  margin: 10px 0;
  font-size: 35px;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -90%);
  z-index: 2;
  width: 100%;
  line-height: 30px;
  overflow: hidden;
  transition: 250ms ease;
  padding: 5px;
}

.image {
  position: absolute; /* Absolute position the image */
  width: 100%; /* Make it as wide as the parent */
  height: 100%; /* Make it as tall as the parent */
  object-fit: cover; /* Do not distort the image */
  top: 0; /* Align the image to the top */
  left: 0; /* Align the image to the left */
  transform: scale(1.2);
  transition: 250ms ease;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.4); /* Black overlay with 0.3 opacity */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  .cardContainer {
    width: 100vw;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    overflow: visible;
    padding: 10px;
  }
  .title {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

@media screen and (max-width: 350px ){
  .cardLink {
    border-radius: 15px;
  }
  .card {
    border-radius: 15px;
  }
}
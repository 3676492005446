.admin-container {
    width: 50%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .admin-button {
    margin: 20px 0;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #e02780;
    color: white;
    cursor: pointer;
  }
  
  .admin-button:disabled {
    background-color: #ddd;
    color: #aaa;
    cursor: not-allowed;
  }
  

.cursor {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    border: 1px solid #01a2e1;
    transition: all 100ms ease-out;
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    transform: translate(calc(-50% + 15px), -50%);
    z-index: 9999999;
  }
  
  .cursor2 {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: #e30679;
    opacity: .3;
    position: fixed;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: width .3s, height .3s, opacity .3s;
    z-index: 9999999;
  }
  
  .hover {
    height: 90px;
    width: 90px;
    opacity: .5;
    border: #01a2e1 10px solid;
  }
  
  .cursorinnerhover {
    width: 70px;
    height: 70px;
    opacity: .5;
  }
.footer-container {
    position: relative;
    z-index: 2;
}
.footer {
    background-color: #282828;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 50px 100px;
    flex-wrap: wrap-reverse;
    direction: rtl;
    position:relative;
    z-index: 1;
}

.footer__info {
    flex: 1;
}

.footer__logo {
    max-width: 100px;
}

.footer__email,
.footer__phone,
.footer__address {
    margin-top: 10px;
}

.footer__products {
    flex: 1;
}

.footer__heading {
    margin-bottom: 10px;
    color: #018bd3;
    font-size: 20px;
}

.footer__list {
    list-style: none;
    padding: 0;
}
.footer__list li:hover {
    color: #e30679;
}
.svg-container {
    z-index: 1;
    position:relative;
  }
  
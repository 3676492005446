.sp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.sp-title {
  font-size: 2.5rem;
  color: white;
  font-weight: 900;
  margin-bottom: 1rem;
  width: 100vw;
  background-image: linear-gradient(to left, #00afee, #0083b3);
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sp-subtitle {
  font-size: 1.5rem;
  color: #777;
  margin-bottom: 2rem;
}

.sp-no-results {
  font-size: 1.25rem;
  color: #555;
}

.sp-results-found {
  font-size: 1.25rem;
  color: #555;
  margin-bottom: 2rem;
}

.sp-search-results {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  overflow: visible;
  width: 80vw;
}

.sp-product-item {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ddd;
  transition: all 0.3s ease-in-out;
}

.sp-product-item:hover {
  transform: scale(1.05);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.sp-product-link {
  color: inherit;
  text-decoration: none;
}

.sp-product-image-container {
  width: 100%;
  padding-top: 100%;
  position: relative;
  background-color: #f2f2f2;
  overflow: hidden;
}

.sp-product-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.sp-product-item:hover .sp-product-image {
  transform: scale(1.1);
}

.sp-product-name {
  padding: 1rem;
  font-size: 1.25rem;
  color: #333;
  text-align: center;
  background-color: #fff;
  line-height: 100%;
}

/* Responsive Design */

@media (max-width: 1200px) {
  .sp-search-results {
    grid-template-columns: repeat(2, 1fr);
    width: 100vw;
  }
}

@media (max-width: 800px) {
  .sp-search-results {
    grid-template-columns: repeat(2, 1fr);
    width: 100vw;
    padding: 20px;
  }
}

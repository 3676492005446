.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 160px);
    flex-direction: column;
  }
  
  .card-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid black;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
  }
  
  
  .card-container input {
    margin-bottom: 10px;
    border: 1px solid black;
    padding: 7px 10px;
    width: 250px;
  }
  
  .card-container button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .card-container button:hover {
    background-color: #0056b3;
  }
  
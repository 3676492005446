.product-card-link {
  text-decoration: none;
  color: inherit;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.product-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 300px;
  overflow: visible;
  background-color: white;
}

.product-card:hover {
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  background-color: white;
  border: none;
}

.product-image-container {
  width: 100%;
  height: 60%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-card-icons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 20px;
  overflow: visible;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #282828;
  font-size: 24px;
  transition: color 0.3s ease, transform 0.3s ease;
  outline: none;
}

.icon-button.active {
  animation: fill 0.5s;
}

.heart-icon.active {
  color: red;
}

.cart-icon.active {
  color: #282828;
}

@keyframes fill {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 600px) {
  .product-card {
    width: 90%;
    height: auto;
    margin: 10px;
    padding: 10px;
  }

  .product-image-container {
    height: 150px;
  }
  .product-card:hover {
    box-shadow: none;
    transform: none;
    background-color: none;
    border: 1px solid #ccc;
  }
}

.cart-button {
  position: relative;
  padding: 10px;
  height: 55px;
  overflow: hidden;
  border: 0;
  border-radius: 5px;
  background-color: #282828;
  color: white;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  width: 100%;
}

.cart-button .fa-shopping-cart {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  font-size: 1.5rem;
  transform: translate(-50%, -50%);
}
.cart-button .fa-box {
  position: absolute;
  z-index: 3;

  left: 51%;
  font-size: 1.1rem;
  transform: translate(-50%, -50%);
  color: white;
  transition: 0.3s ease-in-out;
}
.cart-button span {
  display: none;
}

@keyframes imgAnim {
  0% {
    opacity: 0;
    height: 100vh;
  }
  20% {
    opacity: 1;
    height: 100vh;
  }
  100% {
    height: 100px;
  }
}
@keyframes containerAnim {
  0% {
    background-color: white;
    opacity: 1;
  }
  20% {
    opacity: 1;
    background-color: white;
  }
  100% {
    background-color: none;
    opacity: 0;
  }
}

@keyframes carouselAnim {
  0% {
    opacity: 0;
  }
}

.heroContentStyle {
  height: calc(100vh - 160px);
  width: 100vw;
  display: flex;
}

.banner-animation {
  width: calc(100%);
  object-fit: cover;
  max-width: 1100px;
  line-height: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  pointer-events: none;
  clip-path: inset(1px 1px);
  animation: banner-animation 0.8s ease 1;
}
.banner-animation:hover {
  border: none;
  outline: none;
  box-shadow: none;
}
.banner-animation:focus {
  outline: none;
}
#hero-banner-container {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
#hero-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@keyframes banner-animation {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
#hero-content-container {
  background-color: #282828;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  padding-bottom: 40px;
}
.hero-content {
  display: flex;
  width: fit-content;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  background-color: #282828;
}

.hero-left-half {
  width: 50%;
}

.hero-right-half {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow: visible;
}

#hero-description {
  font-size: 3rem;
  color: white;
  text-align: center;
}

.hero-button {
  padding: 10px 0px;
  width: 300px;
  margin: 1rem;
  border: none;
  cursor: pointer;
  color: white;
  border: 2px solid #e02780;
  border-radius: 4px;
  font-size: 20px;
  transition: 300ms ease;
  text-align: center;
}
#hero-buttons-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
#hero-categories {
  background-color: #e02780;
  color: white;
}
#hero-categories:hover {
  background-color: #2da0e4;
}
.hero-button:hover {
  background-color: #2da0e4;
  color: white;
  transform: translateY(-10px);
  border: 2px solid #2da0e4;
}

@media screen and (max-width: 1170px) {
  .hero-button {
    width: 200px;
  }
}
@media only screen and (max-width: 930px) {
  .hero-content {
    flex-direction: column;
  }

  .hero-left-half, .hero-right-half {
    width: 95vw;
    align-items: center;
    justify-content: center;
  }
  #hero-buttons-container {
    flex-direction: column;
  }
}

:root {
  --bg-color: #282828d8;
}

#about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  overflow: visible;
  direction: rtl;
  max-height: 500px;
  height: 500px;
  margin-top: 100px;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  height: 100%;
  overflow: visible;
  position: relative;
}

.image-container::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 100%;
  background: var(--bg-color);
  border-radius: 40px 0px 0 40px;
  z-index: -1;
  right: 0;
}

.office-image {
  width: calc(100% - 200px); /* Adjust as needed */
  max-width: 500px;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 0.6);
  z-index: 100;
  position: absolute;
  right: -100px;
}


.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 50px;
  overflow: visible;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  padding: 100px;
}

.text-container * {
  overflow: visible;
}
#about-title {
  font-size: 60px;
  color: white;
  margin-bottom: 10px;
  font-weight: 700;
}

#about-description {
  max-width: 600px;
  direction: rtl;
  font-size: 20px;
  color: white;
  margin-bottom: 20px;
  font-weight: 100;
}

.readMoreStyle {
  padding: 10px 20px;
  background-color: #01a2e1;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  transition: 0.2s ease;
}

.readMoreStyle:hover {
  transform: translateY(-5px);
}

/* Responsive styles */
@media screen and (max-width: 1050px) {
  #about-container {
    flex-direction: column-reverse;
    max-height: 1000px;
    height: auto;
    margin-top: 50px;
  }

  .image-container {
    justify-content: center;
    width: 80%;
    margin-top: -25px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-container::before {
    display: none;
  }
  .office-image {
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.2);
    right: 0;
  }

  .text-container {
    width: 80%;
    height: auto;
    margin: 0 auto;
    padding: 50px;
  }

  #about-title {
    font-size: 40px;
    margin-bottom: 20px;
  }

  #about-description {
    max-width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .readMoreStyle {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 669px) {
  .image-container {
    width: 100%;
    height: auto;
    margin: 10px 0;
  }

  .office-image {
    width: 90%;
    margin: 10px auto;
  }

  .text-container {
    width: 90%;
    padding: 20px;
  }

  #about-title {
    font-size: 30px;
    margin-bottom: 15px;
  }

  #about-description {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .readMoreStyle {
    padding: 5px 10px;
  }
}

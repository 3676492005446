.buttons {
  display: flex;
  justify-content: space-around;
  top: 20px;
  left: 20px;
}

.buttons * {
  overflow: visible;
}
.buttons a {
  width: 180px;
  height: 50px;
  background: #2da0e4;
  margin: 20px;
  color: white;
  position: relative;
  overflow: hidden;
  font-size: 1.3rem;
  line-height: 1.3rem;
  letter-spacing: 1px;
  font-weight: ligher;
  text-transform: uppercase;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  text-align: center;
}
.buttons a:hover {
  border-radius: 0px;
  background-color: #2da0e4;
}
.buttons a:before,
.buttons a:after {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  background-color: #e02780;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons a:before {
  right: 0;
  top: 0;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons a:after {
  left: 0;
  bottom: 0;
}

.buttons a span {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.buttons a span:before,
.buttons a span:after {
  content: "";
  position: absolute;
  width: 3px;
  height: 0;
  background-color: #e02780;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons a span:before {
  right: 0;
  top: 0;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.buttons a span:after {
  left: 0;
  bottom: 0;
}

.buttons a p {
  padding: 0;
  margin: 0;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
  position: absolute;
  width: 100%;
  height: 100%;
}

.buttons a p:before,
.buttons a p:after {
  position: absolute;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
  z-index: 1;
  left: 0;
}

.buttons a p:before {
  content: attr(data-title);
  top: 50%;
  transform: translateY(-50%);
}

.buttons a p:after {
  content: attr(data-text);
  top: 150%;
  color: white;
}

.buttons a:hover:before,
.buttons a:hover:after {
  width: 100%;
}

.buttons a:hover span {
  z-index: 1;
}

.buttons a:hover span:before,
.buttons a:hover span:after {
  height: 100%;
}

.buttons a:hover p:before {
  top: -81%;
}

.buttons a:hover p:after {
  top: 50%;
  transform: translateY(-50%);
}

.buttons a.start {
  background-color: #44d8a4;
  box-shadow: 0px 5px 10px -10px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.buttons a.start p:before {
  top: -50%;
}

.buttons a.start p:after {
  color: white;
  transition: all 0.3s ease;
  content: attr(data-start);
  top: 50%;
  transform: translateY(-50%);
  animation: start 0.3s ease;
  animation-fill-mode: forwards;
}

@keyframes start {
  from {
    top: -50%;
  }
}

.buttons a.start:hover:before,
.buttons a.start:hover:after {
  display: none;
}

.buttons a.start:hover span {
  display: none;
}

.buttons a:active {
  outline: none;
  border: none;
}

.buttons a:focus {
  outline: 0;
}

.category-item {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .category-item:hover {
    background-color: #f0f0f0;
  }
  
.add-category-container {
  padding: 20px;
}

.add-category-form {
  display: flex;
  flex-direction: column;
}

.add-category-form input[type="text"] {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-category-form button {
  align-self: center;
  padding: 7px 20px;
  color: white;
  font-size: 20px;
}

.add-category-form button[type="submit"] {
  background-color: #2da0e4;
}
.remove-category {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.remove-category:hover {
  background-color: #da190b;
}
.change-image {
  background-color: #43a800;
  color: white;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.change-image:hover {
  background-color: #388b00;
}

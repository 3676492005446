.categories-container {
  padding: 20px;
}

.category-links {
  text-align: right;
  margin-bottom: 10px;
  align-self: flex-start;
  margin-right: 10vw;
}

.category-links a {
  text-decoration: none;
  font-size: 20px;
  color: #2da0e4;
}

.category-links a:hover {
  text-decoration: underline;
}
.category-links span {
  color: #282828;
  font-size: 20px;
}
.category-cards-container {
  overflow: visible;
}
.products-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  width: 80vw;
  overflow: visible;
  align-items: center;
}

@media (max-width: 600px) {
  .products-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px;
    width: 100vw;
  }
  .product-card {
    width: 90%;
    height: auto;
  }
  .categories-container {
    padding: 0px;
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

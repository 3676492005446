.categories-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  overflow: visible;
  z-index: 2;
}

#categories-title {
  color: #01a2e1;
  font-size: 60px;
}

.category-cards {
  display: grid;
  width: 90vw;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Increased minimum size to 250px */
  grid-gap: 20px;
  overflow: visible;
  margin-top: 50px; 
  align-items: center;
  justify-content: center;
}

.go-to-categories {
  background-color: #282828;
  padding: 6px 15px;
  color: white;
  background-color: #01a2e1;
  transition: transform 0.3s ease;
  font-size: 20px;
  scale: 1.3;
  z-index: 2;
  margin-top: 20px;
}

.go-to-categories:hover {
  transform: translateY(-7px);
  color: #fef000;
  background-color: #01a2e1;
}

@media (max-width: 600px) {
  #categories-title {
    color: #01a2e1;
    font-size: 40px;
    max-width: 600px;
  }
  .category-cards {
    margin-top: 30px;
    padding: 20px;
    width: 100vw;
    grid-template-columns: repeat(2, 1fr); /* Set the grid to have 2 columns */
    grid-gap: 20px;
    overflow: visible;
  }
  .go-to-categories {
    background-color: #282828;
    padding: 6px 15px;
    color: white;
    background-color: #01a2e1;
    transition: transform 0.3s ease;
    font-size: 20px;
    scale: 1.3;
    z-index: 2;
    margin-top: 20px;
  }
}

#leads-manager-container {
  width: 90%;
  margin: auto;
}

#leads-manager-container table {
  width: 100%;
  border-collapse: collapse;
}

#leads-manager-container table th,
#leads-manager-container table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

#leads-manager-container table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
}
#leads-manager-container table input[type="text"] {
  width: 100%;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

#leads-manager-container table button {
  background-color: #4caf50;
  color: white;
  padding: 6px 12px;
  margin: 8px 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

#leads-manager-container table button:hover {
  background-color: #45a049;
}

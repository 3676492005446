#clients-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.our-clients {
  max-width: 1300px;
  overflow: hidden;
  position: relative;
  direction: rtl;
  text-align: center;
  padding-top: 50px;
}

.clients-title {
  font-size: 50px;
  color: #282828;
  font-weight: 900;
  z-index: 2;
  direction: rtl;
  line-height: 100%;
  overflow: visible;
}

.client-logos-wrapper {
  display: flex;
  animation: scroll 30s linear infinite;
  width: 8364.76px;
}

.client-logos {
  display: flex;
}

.client-logo {
  flex: 0 0 auto;
  width: auto;
  height: 165px;
  padding: 20px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50%); /* shift equal to one .client-logos div */
  }
}
@media screen and (max-width: 768px) {
  .clients-title {
    font-size: 3rem;
  }
  .client-logo {
    height: 120px;
  }
  .client-logos-wrapper {
    width: 5785.38px;
  }
}
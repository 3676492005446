.admin-add-image-container {
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f4f4f4;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .add-image-title {
    font-size: 30px;
    font-weight: 700;
  }
  
  .add-image-form {
    display: flex;
    flex-direction: column;
  }
  
  .add-image-input-text,
  .add-image-input-file,
  .add-image-button {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .add-image-button {
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .add-image-button:hover {
    background-color: #0056b3;
  }
  
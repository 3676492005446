.links.active-link {
  color: #01a2e1;
}
.active-link {
  color: #01a2e1 !important;
}
.active-link::after {
  width: 100%;
  right: 0;
}

.links.active::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: #01a2e1;
  bottom: 2px;
  right: 0;
  opacity: 1;
}
#header-container {
  z-index: 100;
  width: 100%;
  height: 160px;
  box-shadow: 0 4px 30px -2px gray;
  position: relative;
  overflow: visible;
}
#small-header {
  background-color: #282828;
  height: 55px;
  display: flex;
  flex-direction: row;
  direction: rtl;
}
#main-header {
  height: 105px;
  background-color: white;
  display: flex;
  align-items: center;
  z-index: 10;
}
#div1Header {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
#div2Header {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  z-index: 11;
  overflow: visible;
}

#searchBar {
  padding: 7px 17px;

  outline: none;
  color: #282828;
  width: 250px;
  width: right;
  transition: 300ms ease;
  direction: rtl;
}
#searchBar::placeholder {
  color: rgb(80, 80, 80);
}
.searchLabel {
  color: white;
  padding-left: 10px;
}

.smallText {
  color: white;
  margin-left: 20px;
}
.icon {
  margin-left: 5px;
}
.logoS {
  height: 100%;
  margin-left: 150px;
}

#leftDiv {
  direction: rtl;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#rightDiv {
  direction: rtl;
  width: 50%;
  height: center;
  display: flex;
  justify-content: center;
}
#rightDiv a.active {
  color: #01a2e1;
}

.links {
  position: relative;
  padding: 10px;
  font-size: 20px;
  color: #282828;
  transition: 0.2s;
  text-decoration: none;
}

.links::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 3px;
  background: #01a2e1;
  transition: width 0.2s ease;
  bottom: 2px;
  right: 0; /* Change from left to right */
}

.links:hover::after {
  right: 0;
  opacity: 1;
  width: 100%;
}

.links:hover {
  color: #01a2e1;
}

.links-button {
  background-color: #01a2e1;
  color: white;
  height: 100%;
  text-align: center;
  font-size: 25px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 25px;
  box-shadow: 0px 0px 10px -5px #000000;
  z-index: 2;
  position: relative;
  text-decoration: none;
  transition: 200ms ease;
}

.links-button:hover {
  color: white;
  width: 220px;
  box-shadow: 0px 0px 20px -5px #000000;
}
#leftDiv .fa-shopping-cart {
  color: #282828;
  font-size: 1.6rem;
  margin-top: 10px;
  position: relative;
}

.cart-count {
  position: relative;
  top: -10px;
  right: 0px;

  background-color: #282828;
  color: #ffffff;
  padding: 2px 5px;
  border-radius: 50%;
  font-size: 0.8em;
  animation: countChange 0.5s ease-out; /* count change animation */
}
.search-results {
  position: absolute;
  width: 250px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: visible;
}

.search-result {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.search-result img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.search-result:last-child {
  margin-bottom: 0;
}
.social-header-icon {
  height: 30px;
  margin: 5px;
}